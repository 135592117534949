import React from 'react'
import { Link } from 'gatsby'

import carouselOne from '/static/images/use-case/by-industry/publisher_feature_1.webp'
import carouselTwo from '/static/images/use-case/by-industry/publisher_feature_2.webp'
import carouselThree from '/static/images/use-case/by-industry/publisher_feature_3.webp'
import { clickMessageBirdChat } from 'utils'

export const publisherFeatures = [
  {
    title: 'Leadership in Speed and SEO',
    content:
      'Mlytics’ AI-optimized networking infrastructure ensures sub-second global content delivery, dynamically adjusting asset compression levels based on real-time device specs, network conditions, and user intent. This eradicates slow-loading penalties, propelling websites into top SEO rankings.',
    imageSrc: carouselOne,
    imageAlt: 'Leadership in Speed and SEO',
    children: (
      <div>
        <Link to="/use-case/ai-generated-content" class="block h5-subtext-medium text-blue-100 underline py-3 w-fit">
          Learn more.
        </Link>
        <button onClick={clickMessageBirdChat} className="bg-secondary-600 rounded w-fit h-full py-2 px-3 text-white">
          See demo
        </button>
      </div>
    )
  },
  {
    title: 'Invest in New Methods, not Labor',
    content:
      'AI networks automate labor-intensive tasks – Mlytics Platform audits and transform delivered assets in real time, applying lossless compression and predictive caching. This prevents heavy investment on traditional labor and extensive human errors in the creative process.',
    imageSrc: carouselTwo,
    imageAlt: 'Invest in New Methods, not Labor',
    children: (
      <button onClick={clickMessageBirdChat} className="bg-none h5-subtext-medium text-blue-100 underline my-3 w-fit">
        Learn more.
      </button>
    )
  },
  {
    title: 'Engagement-to-Revenue Flywheel',
    content:
      'Mlytics-powered LLMs analyze user behavior to generate hyper-personalized content recommendations and interactive element and map engagement hotspots to deploy contextually relevant ads to achieve higher CTR. This approach monetizes attention without disrupting experience, turning passive viewers into revenue-generating participants.',
    imageSrc: carouselThree,
    imageAlt: 'Engagement-to-Revenue Flywheel',
    children: (
      <button onClick={clickMessageBirdChat} className="bg-none h5-subtext-medium text-blue-100 underline my-3 w-fit">
        Learn more.
      </button>
    )
  }
]
