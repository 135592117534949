import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from 'layouts/index'
import Seo from 'components/seo'
import Cover from 'components/common/cover'
import WideCard from 'components/common/wideCard'
import UseCase from 'components/common/useCase'
import ContentCarousel from 'components/common/contentCarousel'
import publisher from '/static/images/use-case/by-industry/publisher_hero.webp'
import publisherCard from '/static/images/use-case/by-industry/publisher_bar.webp'
import { publisherFeatures } from '/static/data/use-case/publisher.static'

const Publisher = () => {
  return (
    <Layout>
      <React.Fragment>
        <Seo
          seo={{
            metaTitle: `Use Case: Publisher - Mlytics`,
            metaDescription: `Mlytics' AI-based platform optimizes digital asset delivery, enhancing user engagement and revenue through improved advertising strategies, leveraging AI for faster and more secure content distribution.`,
            metaUrl: 'www.mlytics.com/use-case/publisher',
            shareImage: {
              imageUrl: 'https://www.mlytics.com/wp-content/uploads/2023/03/og_v5.jpg',
              imageWidth: 1200,
              imageHeight: 630,
              imageType: 'image/jpeg'
            }
          }}
        />
        <section
          style={{
            height: 'calc(100vh - 100px)',
            minHeight: '600px'
          }}
          className="px-9 md:px-[86px] mb-5 lg:max-h-[800px] base:absolute base:left-1/2 base:-translate-x-1/2 base:w-screen base:max-w-[1920px]"
        >
          <Cover
            smallTitle="Publisher"
            title="Your Digital Assets with AI Technologies"
            imgSrc={publisher}
            imgAlt="Publisher Hero Picture"
            paragraphContent="With AI-powered optimization, publishers now accelerate digital assets delivery across all devices, boost engagement through intelligent adaptation and maximize ad revenue in competitive markets."
            actionLinkOne="chatbot"
            actionTextOne="Explore more"
          />
        </section>
        <div className="hidden mb-5 lg:max-h-[800px] base:block base:h-[calc(100vh-100px)] base:min-h-[600px]"></div>
        <section className="py-[60px] px-9 md:px-[86px]">
          <div className="w-full flex flex-col gap-10">
            <WideCard
              imgSrc={publisherCard}
              imgAlt="For Publisher"
              actionLink="/contact-us"
              actionText="Contact us"
              actionColor="btn-cover-orange"
            >
              <div className="py-[60px] px-10 w-[60%] bg-experience-gradient flex flex-col gap-5">
                <p className="h5-subtext-medium text-white">
                  Mlytics' Platform leverages both our outstanding networking and cutting-edge AI LLMs to auto-optimize
                  digital assets and generate dynamic content to enrich user engagement. Tasks Includes:
                </p>
                <ul className="flex flex-col h5-subtext-medium text-white ml-7">
                  <li className="list-disc">Digital assets optimization from the cloud</li>
                  <li className="list-disc">LLM-powered asset auto tagging</li>
                  <li className="list-disc">Organic content generating and categorizing</li>
                  <li className="list-disc">New Ads inventory generating</li>
                </ul>
                <Link
                  to="/contact-us"
                  className="btn-cover-orange h4-content-text-bold text-white bg-secondary-600 px-[18px] py-3 rounded w-fit"
                >
                  Contact us
                </Link>
              </div>
            </WideCard>
          </div>
        </section>
        <section className="py-[60px] px-9 md:px-[86px]">
          <div className="w-full flex flex-col gap-10">
            <ContentCarousel listItem={publisherFeatures} />
          </div>
        </section>
        <section className="py-[60px]">
          <UseCase />
        </section>
      </React.Fragment>
    </Layout>
  )
}

export default Publisher

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
